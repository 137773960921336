import React, { useContext, useEffect, useRef, useState } from "react";
import styles from "./C03.1_SubNavigationDE.module.scss";
import Cta from "@components/Cta/Cta";
import { themr } from "@friendsofreactjs/react-css-themr";
import { useRouter } from "next/router";
import Arrow from "../../public/assets2019/img/svg/arrow-carousel.svg";
import Link from "@components/Link/Link";
import { useDevice } from "@utilities/react/get-device/get-device";
import C721_ProgrammeDetailsDE from "@components/C72.1_ProgrammeDetailsBarDE/C72.1_ProgrammeDetailsBarDE";
import C722_ProgrammeDetailsBarEMbaMerge from "@components/C72.2_ProgrammeDetailsBarEMbaMerge/C72.2_ProgrammeDetailsBarEMbaMerge";
import clsx from "clsx";
import { flushSync } from "react-dom";
import { useProgramme } from "@components/__templates__/ProgrammeDetailsPage/ProgrammeDetailsProvider";
import { useSettings } from "@utilities/context/settings";
import { useProgrammeDetailsPage } from "../../context/programmeDetailsPage";
import { ProgrammeCTAType } from "@customTypes/ProgrammeCTAType";
import { UserContext } from "../../context/user";
import MissingContent from "@components/MissingContentWarning";
import ModalEMBAMergeForm from "@components/ModalEMBAMergeForm/ModalEMBAMergeForm";

export interface SubNavigationDEProps {
  theme?: any;
  content?: any;
}

const SubNavigationDE: React.FC<SubNavigationDEProps> = ({
  content,
  theme,
}: SubNavigationDEProps) => {
  const subNavWrapperRef: any = useRef();
  const listRef: any = useRef();
  const stickyRef: any = useRef();
  const navigationRef: any = useRef();
  const stickComboRef: any = useRef();
  const stickComboWrapRef: any = useRef();
  const [hasArrows, setHasArrows] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [scrollAmount, setScrollAmount] = useState(0);
  const [remainingLength, setRemainingLength] = useState(0);
  const [initialHeight, setInitialHeight] = useState(0);
  const [subNavOpen, setSubNavOpen] = useState(false);
  const [subNavOpenNr, setSubNavOpenNr] = useState(null);

  const {
    ctaButton1,
    ctaButton1Text,
    ctaLink1,
    linkHelpText,
    programmeDetailsBarDe,
    navigationLinks,
    formHeaderEmbaMerge,
    buttonTextEmbaMerge,
    errorMessageEmbaMerge,
  } = content;

  const isC722ProgrammeDetailsBarEMbaMerge =
    programmeDetailsBarDe?.sys?.contentType?.sys?.id ===
    "c722ProgrammeDetailsBarEMbaMerge";

  const regexLondon = /London/i; // Case-insensitive

  const programmeLocationsAndApplyIdCollection =
    isC722ProgrammeDetailsBarEMbaMerge
      ? programmeDetailsBarDe?.fields?.programmeDetailPages?.map((obj) => ({
          value: regexLondon.test(obj?.fields?.programmeTypeCode)
            ? "London"
            : "Dubai",
          label: regexLondon.test(obj?.fields?.programmeTypeCode)
            ? "London"
            : "Dubai",
          applyId: obj?.fields?.programmeApplyOnlineParameterValue,
        }))
      : [];

  const distinctCollection =
    programmeLocationsAndApplyIdCollection.filter(
      (item, index, self) =>
        self.findIndex((obj) => obj.value === item.value) === index
    ) || [];

  const { importDataProgrammeTypeCode, programmeTypeCode, programmeTypeGroup } =
    useProgramme();

  let programmeId = programmeTypeCode;

  if (!programmeTypeCode && isC722ProgrammeDetailsBarEMbaMerge) {
    programmeId = programmeDetailsBarDe?.fields?.programmeDetailPages?.map(
      (obj) => obj["fields"].programmeTypeCode
    )[0];
  } else if (!programmeTypeCode) {
    programmeId =
      content?.programmeDetailsBarDe?.fields?.programmeDetailPage?.fields
        ?.programmeTypeCode;
  }
  const router = useRouter();

  const [showLightbox, setShowLightbox] = useState(false);

  const closeLightbox = () => {
    setTimeout(() => {
      setShowLightbox(false);
    }, 300);
  };

  const { siteSettings } = useSettings();
  const { programmeApplyOnlineParameterValue, type } =
    useProgrammeDetailsPage();

  const apply = siteSettings?.programmeCtaPagesCollection?.items.filter(
    ({ type }) => type === ProgrammeCTAType.Apply
  )[0];

  let programmeApplyOnlineId = programmeApplyOnlineParameterValue;

  if (!programmeApplyOnlineParameterValue) {
    programmeApplyOnlineId =
      content?.programmeDetailsBarDe?.fields?.programmeDetailPage?.fields
        ?.programmeApplyOnlineParameterValue;
  }

  const updateSubNavHeight = (subNav, height) => {
    if (subNav.current) {
      const isMobile = window.innerWidth <= 767;
      if (subNav.current.getBoundingClientRect().top < 1) {
        flushSync(() => {
          setIsSticky(true);
        });
        subNav.current.style.paddingBottom = isMobile ? "136px" : height + "px";
      } else {
        flushSync(() => {
          setIsSticky(false);
        });

        subNav.current.style.paddingBottom = "0px";
      }
    }
  };

  const stickNav = () => {
    const height = stickComboRef.current.getBoundingClientRect().height;
    updateSubNavHeight(stickComboRef, height);
  };

  const scrollTo = (el, anchorId) => {
    const sectionHead = document.querySelector(anchorId);
    const container = stickyRef.current;
    if (sectionHead) {
      const focusTimeout =
        Math.abs(sectionHead.getBoundingClientRect().top / 2) +
        (document.body.clientWidth > 1023 ? 200 : 500);

      setTimeout(
        () => {
          window.scroll({
            top:
              sectionHead.getBoundingClientRect().top +
              (window.scrollY || document.documentElement.scrollTop) -
              container.getBoundingClientRect().height,
            left: 0,
            behavior: "smooth",
          });
        },
        document.body.clientWidth > 1023 ? 0 : 500
      );

      setTimeout(() => {
        sectionHead.focus();
      }, Math.abs(focusTimeout));

      history.replaceState({}, "", el.getAttribute("href"));
    }
  };

  const device = useDevice();

  const onNavClick = (e) => {
    e.stopPropagation();

    if (device === "mobile") {
      setIsExpanded(false);
    }
    const hrefValue = e.target.getAttribute("href");

    if (
      hrefValue.substring(0, hrefValue.lastIndexOf("#")) === router["asPath"]
    ) {
      let anchorId = hrefValue.substring(hrefValue.lastIndexOf("#"));

      if (anchorId.charAt(0) === "#") {
        e.preventDefault();
        scrollTo(e.target, anchorId);
      }
    }
  };

  const setDropdownLabel = () => {
    try {
      const firstLinkText = navigationLinks[0]?.fields?.linkText;
      const activeLi = navigationLinks.find(
        (link) => link?.fields?.url === router["asPath"]
      );

      if (activeLi) {
        return activeLi.fields.linkText;
      } else {
        return firstLinkText;
      }
    } catch (e) {
      return <MissingContent error={e} component="C03.1_SubnavigationDE.tsx" />;
    }
  };

  const LiJSX = ({
    linkText,
    openInNewTab,
    url,
    contentReference,
  }: {
    href?: string;
    url: string;
    linkText: string;
    openInNewTab?: boolean;
    contentReference: { fields: { slug: string } };
  }) => {
    const linkUrl = contentReference?.fields?.slug
      ? `/${contentReference.fields.slug}`
      : url;

    const linkTarget = openInNewTab ? { target: "_blank" } : null;

    return (
      <li className={router["asPath"] === url ? theme.active : ""}>
        <Link href={linkUrl || "#"} passHref>
          <a {...linkTarget} onClick={onNavClick}>
            {linkText}
          </a>
        </Link>
      </li>
    );
  };

  const updateScrollButtons = (scrollLeft: number, excess: number) => {
    setShowLeftArrow(scrollLeft > 0);
    setShowRightArrow(scrollLeft < excess - 30);
  };

  useEffect(() => {
    const wrapperLength = subNavWrapperRef.current
      ?.querySelector(".wrapper")
      ?.getBoundingClientRect()?.width;

    const liList = subNavWrapperRef?.current?.querySelectorAll(
      ".secondary-nav-links li"
    );
    if (liList) {
      const count = [...liList].reduce(
        (accumulator, currentValue) =>
          accumulator + currentValue.getBoundingClientRect().width,
        0
      );

      const listLength = Math.round(count);

      let scrollAmountVal =
        listLength > wrapperLength * 2
          ? wrapperLength
          : listLength - wrapperLength;

      scrollAmountVal = scrollAmountVal + 30;

      setScrollAmount(scrollAmountVal);

      setRemainingLength(listLength - wrapperLength);
      updateScrollButtons(0, listLength - wrapperLength);

      if (listLength > wrapperLength) {
        setHasArrows(true);
      }
    }
  }, []);

  const onRightArrowClick = (e) => {
    listRef.current.scrollBy({
      top: 0,
      left: scrollAmount,
      behavior: "smooth",
    });

    setTimeout(() => {
      updateScrollButtons(listRef.current.scrollLeft, remainingLength);
    }, 500);
  };

  const onLeftArrowClick = () => {
    listRef.current.scrollBy({
      top: 0,
      left: -scrollAmount,
      behavior: "smooth",
    });

    setTimeout(() => {
      updateScrollButtons(listRef.current.scrollLeft, remainingLength);
    }, 500);
  };

  const onListScroll = () => {
    updateScrollButtons(listRef.current.scrollLeft, remainingLength);
  };

  useEffect(() => {
    const wrapper = stickyRef.current.querySelector(`.wrapper`);
    const condensedNavHeight = wrapper.getBoundingClientRect().height;
    setInitialHeight(condensedNavHeight);

    window.addEventListener("scroll", stickNav);
    return () => {
      window.removeEventListener("scroll", stickNav);
    };
  }, []);

  useEffect(() => {
    const wrapper = stickyRef.current.querySelector(`.wrapper`);
    const primaryList = stickyRef.current.querySelector(`.subnav-links`);
    const listHeight = primaryList.getBoundingClientRect().height;
    if (device === "tablet" || device === "mobile") {
      if (isExpanded) {
        wrapper.getBoundingClientRect().height =
          wrapper.getBoundingClientRect().height;

        wrapper.style.height = listHeight + "px";
      } else {
        wrapper.style.height = initialHeight + "px";
      }
    } else {
      wrapper.style.height = "8.2rem";
    }
  }, [isExpanded, device]);

  const onHasSubNavClick = (itemId) => {
    setSubNavOpenNr(itemId);
    setSubNavOpen(true);
  };

  const onHasSubNavKeyDown = (e, itemId) => {
    if (e.which === 13 || e.which === 32) {
      setSubNavOpenNr(itemId);
      setSubNavOpen(true);
    }
  };
  const onNavLabelKeydown = (e) => {
    if (e.which === 13 || e.which === 32) {
      setSubNavOpen(true);
    }
  };

  const onBackLinkClick = () => {
    setSubNavOpen(false);
    setSubNavOpenNr(null);
  };

  const onBackLinkKeyDown = () => {
    setSubNavOpen(false);
    setSubNavOpenNr(null);
  };

  const { user } = useContext(UserContext);

  return (
    <>
      <div
        className={clsx(
          theme.component,
          theme["subnav-combo"],
          isSticky && theme["stick-combo"]
        )}
        ref={stickComboRef}
      >
        <div ref={stickComboWrapRef} className={theme["sticky-combo-wrap"]}>
          {isC722ProgrammeDetailsBarEMbaMerge && programmeDetailsBarDe ? (
            <C722_ProgrammeDetailsBarEMbaMerge
              isSticky={isSticky}
              content={programmeDetailsBarDe}
              programmeId={programmeId}
            />
          ) : (
            <C721_ProgrammeDetailsDE
              isSticky={isSticky}
              content={programmeDetailsBarDe}
              programmeId={programmeId}
            />
          )}
          <div
            ref={navigationRef}
            className={clsx(
              theme.component,
              theme["sub-navigation-two-level"],
              "blue",
              isExpanded && theme.open,
              subNavOpen && theme["subnav-open"]
            )}
          >
            <div ref={stickyRef} className={theme["sticky-wrap"]}>
              <div
                className={clsx("wrapper", theme.wrapper, theme["nav-wrapper"])}
              >
                <ul className={`${theme["subnav-links"]} subnav-links`}>
                  <li
                    onKeyDown={onNavLabelKeydown}
                    onClick={() => setIsExpanded(!isExpanded)}
                    className={clsx(theme["nav-label"], theme["d-hide"])}
                    aria-expanded={isExpanded ? "false" : "true"}
                  >
                    {setDropdownLabel()}
                    <div className={clsx(theme.tick, theme["icon-chevron"])}>
                      <i className={clsx(theme["icon-chevron"])}>
                        <Arrow></Arrow>
                      </i>
                    </div>
                  </li>
                  {navigationLinks?.map((link, i) => (
                    <React.Fragment key={`${i}${link.fields?.linkText}`}>
                      {link.fields.linkText ? (
                        <LiJSX {...link.fields} />
                      ) : (
                        <li
                          onKeyDown={(e) =>
                            onHasSubNavKeyDown(
                              e,
                              `${i}--${link.fields.linkText}`
                            )
                          }
                          className={clsx(
                            theme["has-subnav"],
                            subNavOpen &&
                              subNavOpenNr ===
                                `${i}--${link.fields.linkText}` &&
                              theme.open
                          )}
                          tabIndex={0}
                        >
                          <span className={clsx(theme["has-subnav-main-link"])}>
                            <Link href={link.fields.dropdownLinkHref || "#"}>
                              <a
                                onClick={onNavClick}
                                id="subnav-main-link"
                                className={clsx(theme["subnav-main-link"])}
                              >
                                {link.fields.dropdownLink}
                              </a>
                            </Link>
                            <i
                              className={clsx(theme["icon-chevron"])}
                              onTouchStart={() => {
                                onHasSubNavClick(
                                  `${i}--${link.fields.linkText}`
                                );
                              }}
                            >
                              <Arrow></Arrow>
                            </i>
                          </span>
                          <div
                            ref={subNavWrapperRef}
                            className={clsx(
                              theme["secondary-nav"],
                              hasArrows && theme["has-arrows"]
                            )}
                          >
                            <div className={clsx(theme.wrapper, "wrapper")}>
                              <button
                                onClick={onLeftArrowClick}
                                className={clsx(
                                  theme["scroll-left"],
                                  !showLeftArrow && theme.hidden
                                )}
                                tabIndex={-1}
                              >
                                <i className={theme["icon-chevron"]}>
                                  <Arrow></Arrow>
                                </i>

                                <span className={theme.hidden}>
                                  Scroll left
                                </span>
                              </button>
                              <span
                                onKeyDown={onBackLinkKeyDown}
                                onClick={onBackLinkClick}
                                className={clsx(
                                  theme["mobile-only"],
                                  theme["back-link"]
                                )}
                              >
                                <i className={theme["icon-chevron"]}>
                                  <Arrow></Arrow>
                                </i>
                                Back
                              </span>
                              <ul
                                ref={listRef}
                                onScroll={onListScroll}
                                className={clsx(
                                  theme["secondary-nav-links"],
                                  showLeftArrow && theme["left-arrow"],
                                  showRightArrow && theme["right-arrow"],
                                  "secondary-nav-links"
                                )}
                              >
                                {link.fields.secondaryNavLinks.map(
                                  (dropdownLink, i) => (
                                    <LiJSX
                                      key={`${i}${dropdownLink.fields?.linkText}`}
                                      {...dropdownLink.fields}
                                    />
                                  )
                                )}
                              </ul>
                              <button
                                onClick={onRightArrowClick}
                                className={clsx(
                                  theme["scroll-right"],
                                  !showRightArrow && theme.hidden
                                )}
                                tabIndex={-1}
                              >
                                <i className={theme["icon-chevron"]}>
                                  <Arrow></Arrow>
                                </i>
                                <span className={theme.hidden}>
                                  Scroll right
                                </span>
                              </button>
                            </div>
                          </div>
                        </li>
                      )}
                    </React.Fragment>
                  ))}
                </ul>
                <div className={theme["button-wrappers"]}>
                  {ctaButton1 &&
                    (ctaButton1 === "Use CTA Link 1" && ctaLink1 ? (
                      <Cta
                        id="cta-link1"
                        {...ctaLink1.fields}
                        type="secondary"
                        light
                      />
                    ) : ctaButton1 === "Emba Merge Apply Now" ? (
                      <Cta
                        id="emba-merge-apply-now"
                        linkText={
                          ctaButton1 === "Emba Merge Apply Now"
                            ? "Apply Now"
                            : ctaButton1Text
                        }
                        type="secondary"
                        url=""
                        light
                        onClick={() => {
                          setShowLightbox(true);
                        }}
                      />
                    ) : (
                      <Cta
                        id="apply-now"
                        linkText={
                          ctaButton1 === "Apply Now CTA"
                            ? "Apply now"
                            : ctaButton1Text
                        }
                        type="secondary"
                        url={
                          user
                            ? `/interact?applyID=${programmeApplyOnlineId}`
                            : `${apply?.url.slug}?applyID=${programmeApplyOnlineId}`
                        }
                        light
                      />
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showLightbox && (
        <ModalEMBAMergeForm
          locations={distinctCollection}
          formHeader={formHeaderEmbaMerge}
          buttonText={buttonTextEmbaMerge}
          errorMessage={errorMessageEmbaMerge}
          redirectDelay={500}
          onCloseFunction={closeLightbox}
        />
      )}
    </>
  );
};

export default themr("SubNavigationDE", styles)(SubNavigationDE);

import React, { useState } from "react";
import { useSettings } from "@utilities/context/settings";
import { ProgrammeCTAType } from "@customTypes/ProgrammeCTAType";
import styles from "./ModalEMBAMergeForm.module.scss";
import SubmitButton from "@components/SubmitButton/SubmitButton";

const ModalEMBAMergeForm = ({
  locations,
  formHeader,
  buttonText,
  errorMessage,
  redirectDelay,
  onCloseFunction,
}) => {
  const [selectedLocation, setSelectedLocation] = useState<string | null>(null);
  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const { siteSettings } = useSettings();

  // Fetch the "Apply" URL dynamically from Site Settings
  const apply = siteSettings?.programmeCtaPagesCollection?.items.filter(
    ({ type }) => type === ProgrammeCTAType.Apply
  )[0];

  const applyUrlBase = `/${apply?.url?.slug}`;

  const handleLocationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedLocation(event.target.value);
    setError(false);
  };

  const handleSubmit = () => {
    if (!selectedLocation) {
      setError(true);
      return;
    }

    const regexLondon = /London/i; // Case-insensitive

    setLoading(true);
    setTimeout(() => {
      let applyUrl = "";

      // Construct dynamic applyUrl for selected location
      if (regexLondon.test(selectedLocation)) {
        applyUrl = `${applyUrlBase}?applyID=${locations.find((element) => element.value === selectedLocation)?.applyId}`;
      } else {
        applyUrl = `${applyUrlBase}?applyID=${locations.find((element) => element.value === selectedLocation)?.applyId}`;
      }

      if (applyUrl) {
        window.location.href = applyUrl;
      }
    }, redirectDelay);
  };

  return (
    <>
      <div className={styles["modal-emba-merge-form-wrapper"]}>
        <div className={styles["modal-emba-merge-form"]}>
          <button onClick={onCloseFunction} className={styles["close-button"]}>
            &times;
          </button>
          <h2 className={styles["modal-header"]}>{formHeader}</h2>
          <div className={styles["radio-group"]}>
            {locations.map((location, index) => (
              <label key={index}>
                <input
                  type="radio"
                  name="location"
                  value={location.value}
                  onChange={handleLocationChange}
                />
                {location.label}
              </label>
            ))}
          </div>

          {error && <p className={styles["error-message"]}>{errorMessage}</p>}

          <div className={styles["divider"]}></div>

          <div className={styles["btn-and-text-wrapper"]}>
            <div onClick={!loading ? handleSubmit : undefined}>
              <SubmitButton
                loading={loading}
                id="submitButton"
                text={buttonText}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ModalEMBAMergeForm;

import { useEffect, useState, useContext, useRef } from "react";
import { useRouter } from "next/router";
import clsx from "clsx";
import { themr } from "@friendsofreactjs/react-css-themr";
import { useForm } from "react-hook-form";
import ClipLoader from "react-spinners/ClipLoader";

import {
  getBroadIndustries,
  getJobFunctions,
  getIntendedYearsOfStudy,
  getJobLevel,
  getPersonalDetails,
  getTitles,
  setSubmitCVLoggedOut,
  setSubmitCVLoggedIn,
  getInstitutions,
} from "../../services/forms";
import Grid from "@components/Grid/Grid";
import { UserContext } from "../../context/user";
import FormField from "@components/Form/FormFields/FormFields";
import { ProgrammeCode } from "@customTypes/ProgrammeCode";
import { MultipleOption } from "@customTypes/PersonalDetailsType";
import getCookie from "@utilities/getCookie";
import FormErrorMessage from "@components/FormErrorMessage/FormErrorMessage";
import SubmitButton from "@components/SubmitButton/SubmitButton";
import InstitutionSection from "@components/InstitutionSection/InstitutionSection";

import styles from "./SubmitCv.module.scss";
import { useSettings } from "@utilities/context/settings";
import { handleSubmitFormErrors } from "@utilities/handleSubmitFormErrors";
import setGADataLayer from "@utilities/setGADataLayer";
import { getBusinessUnit } from "@utilities/getBusinessUnit";
import {
  LINKEDIN_URL_OR_CV_VALIDATION_MESSAGE,
  LINKEDIN_URL_PATTERN_REG_EXP,
  LOGIN_URL,
} from "../../constants";
import { ProfileDetailsProps, File } from "./interfaces";
import { permanentFields } from "./constants";
import ProgrammesListSection from "@components/ProgrammesListSection/ProgrammesListSection";
import { programmesListData } from "@components/SubmitCv/constants";
import { ProgrammeTypeGroup } from "@customTypes/PropgrammeTypeGroup";
import FormPrivacySection from "@components/FormPrivacySection/FormPrivacySection";
import { findMatchingSelectValue } from "@utilities/mapSelectValue";
import CountryStateSection from "@components/CountryStateSection/CountryStateSection";
import { objectToFormData } from "@components/SubmitCv/objectToFormData";

let firstInteract = 0;

export const SubmitCv = (props: {
  content: ProfileDetailsProps;
  theme?: any;
}) => {
  const { content, theme } = props;
  const {
    emailLabelText,
    firstNameLabelText,
    studyDisciplineLabelText,
    familyNameLabelText,
    homeCountryLabelText,
    workCompanyNameLabelText,
    telephoneNumberHintText,
    expectedGradeLabelText,
    yearsRelevantExperienceLabelText,
    educationalInstitutesLabelText,
    functionLabelText,
    workJobTitleLabelText,
    telephoneNumberLabelText,
    titleLabelText,
    industryLabelText,
    nationalityLabelText,
    executiveAssessmentScoreTitle,
    intendedYearOfStudyLabelText,
    linkedInProfileUrlLabel,
    graduationYearLabelText,
    gmatScoreLabelText,
    homeCityLabelText,
    jobLevelLabelText,
    formTitle,
    formLoggedInTitle,
    introLoggedInText,
    introText,
    embagIntroLoggedInText,
    embagIntroText,
    yearsManagerialExperienceLabelText,
    passwordLabelText,
    passwordHintText,
    confirmPasswordLabelText,
    countryStateLabelText,
    failedMessage,
    institutionLabelText,
    mastersProgrammeDegreeTitleLabelText,
  } = content;

  const [submittedUserEmail, setSubmittedUserEmail] = useState<string>("");
  const [userPersonalDetails, setUserPersonalDetails] = useState<any>(null);
  const [titles, setTitles] = useState<MultipleOption | null>(null);
  const [titlesSelectValue, setTitlesSelectValue] = useState<any>(null);
  const [industries, setIndustries] = useState<MultipleOption | null>(null);
  const [institutions, setInstitutions] = useState<any>("");
  const [error, setError] = useState<boolean>(false);
  const [programmeCode, setProgramCode] = useState<any>(null);
  const [programmeTypeGroup, setProgrammeTypeGroup] = useState<any>(null);
  const [programmesSelectedTypes, setProgrammesSelectedTypes] = useState<any>(
    []
  );
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const [hasSubmitted, setHasSubmitted] = useState<boolean>(false);
  const [genuinePath, setGenuinePath] = useState<any>("");
  const [authToken, setAuthToken] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [handledSubmitFormErrors, setHandledSubmitFormErrors] =
    useState<Array<string> | null>(null);
  const [canContactByEmail, setCanContactByEmail] = useState<boolean>(false);
  const [seniorityLevelsSelectValue, setSeniorityLevelsSelectValue] =
    useState<any>(null);
  const [seniorityLevels, setSeniorityLevels] = useState<any>("");
  const [intendedYearsOfStudySelectValue, setIntendedYearsOfStudySelectValue] =
    useState<any>(null);
  const [intendedYearsOfStudy, setIntendedYearsOfStudy] = useState<any>("");
  const [industriesSelectValue, setIndustriesSelectValue] = useState<any>(null);
  const [institutionsSelectValue, setInstitutionsSelectValue] =
    useState<any>(null);
  const [jobFunctionSelectValue, setJobFunctionSelectValue] =
    useState<any>(null);
  const [jobFunctions, setJobFunctions] = useState<any>("");
  const [countryInputValue, setCountryInputValue] = useState<any>("");
  const [countries, setCountries] = useState<any>(null);
  const [selectedCountry, setSelectedCountry] = useState<string>("");
  const [countriesStateList, setCountriesStateList] = useState<any>([]);
  const [selectedCountryState, setSelectedCountryState] = useState<string>("");
  const [nationalityInputValue, setNationalityInputValue] = useState<any>("");
  const [nationalityCountries, setNationalityCountries] = useState<any>(null);
  const [loggedInStayInformed, setLoggedInStayInformed] = useState<any>(null);
  const [showNationalCountriesList, setShowNationalCountriesList] =
    useState<boolean>(false);
  const [showCountriesList, setShowCountriesList] = useState<boolean>(false);
  const [selectedNationalCountry, setSelectedNationalCountry] =
    useState<string>("");
  const [selectedIdValues, setSelectedIdValues] = useState<any>({
    title: "",
    industry: "",
    jobLevel: "",
    jobFunction: "",
    intendedYearOfStudy: "",
  });
  const { user } = useContext(UserContext);
  const router = useRouter();
  const { apiErrorMessages, siteSettings } = useSettings();

  const {
    register,
    handleSubmit,
    reset,
    formState,
    clearErrors,
    watch,
    setValue,
    control,
  } = useForm({
    mode: "onBlur",
  });
  // @ts-ignore
  const watchedFieldValue = watch("linkedInProfile");
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [loggedInUser, setLoggedInUser] = useState<any>(null);

  const hasInstitutionMatch = (value: string, institutions) =>
    institutions &&
    institutions.find(
      (institution) => institution.value === value || value === "None of these"
    );

  useEffect(() => {
    user ? setLoggedInUser(true) : setLoggedInUser(null);
    if (user) {
      setAuthToken(getCookie("access_token"));
    }
  }, [user]);
  const { query } = useRouter();

  useEffect(() => {
    if (loggedInUser) {
      setCountryInputValue(userPersonalDetails?.result?.address?.homeCountry);
      setSelectedCountry(userPersonalDetails?.result?.address?.homeCountry);
      setSelectedCountryState(
        userPersonalDetails?.result?.address?.countryState
      );
      setNationalityInputValue(userPersonalDetails?.result?.nationality);
      setSelectedNationalCountry(userPersonalDetails?.result?.nationality);
      setTitlesSelectValue(userPersonalDetails?.result?.title);
      setIndustriesSelectValue(userPersonalDetails?.result?.broadIndustry);
      setSeniorityLevelsSelectValue(userPersonalDetails?.result?.jobLevel);
      setJobFunctionSelectValue(userPersonalDetails?.result?.function);
      setLoggedInStayInformed(userPersonalDetails?.result?.isStayInformed);
      setIntendedYearsOfStudySelectValue(
        userPersonalDetails?.result?.intendedYearOfStudy
      );
      setSelectedIdValues({
        title: userPersonalDetails?.result?.title,
        industry: userPersonalDetails?.result?.broadIndustry,
        jobLevel: userPersonalDetails?.result?.jobLevel,
        jobFunction: userPersonalDetails?.result?.function,
        intendedYearOfStudy: userPersonalDetails?.result?.intendedYearOfStudy,
      });
    }
  }, [loggedInUser, userPersonalDetails]);

  useEffect(() => {
    if (!query || !query.programmeTypeCode || !query.programmeTypeGroup) return;

    const programmeCode = query.programmeTypeCode
      ? query.programmeTypeCode
      : "";

    const programmeTypeGroup = query.programmeTypeGroup
      ? query.programmeTypeGroup
      : "";

    if (!programmeCode.length) return;
    setProgramCode(programmeCode);
    setProgrammeTypeGroup(programmeTypeGroup);
  }, [query]);
  const orderYearsOfStudy = (intendedYearsOfStudy) => {
    let today = new Date();
    let year = today.getFullYear();
    return intendedYearsOfStudy.filter((item) => {
      return item.value === "N/A" || item.value >= year;
    });
  };

  useEffect(() => {
    if (loggedInUser) {
      const submitCVFormData = async () => {
        try {
          const personalDetails = await getPersonalDetails(authToken);
          const industries = await getBroadIndustries();
          const seniorityLevels = await getJobLevel();
          const intendedYearsOfStudy = await getIntendedYearsOfStudy();
          const jobFunctions = await getJobFunctions();
          const institutions = await getInstitutions();
          setInstitutions(institutions);
          setIntendedYearsOfStudy(orderYearsOfStudy(intendedYearsOfStudy));
          setSeniorityLevels(seniorityLevels);
          setJobFunctions(jobFunctions);
          setIndustries(industries);
          setUserPersonalDetails(personalDetails);
          setCanContactByEmail(
            loggedInUser && personalDetails?.result?.isStayInformed
          );
        } catch (e) {
          setError(true);
        }
      };
      submitCVFormData();
    } else {
      const submitCVFormData = async () => {
        try {
          const institutions = await getInstitutions();
          const titles = await getTitles();
          const industries = await getBroadIndustries();
          const seniorityLevels = await getJobLevel();
          const intendedYearsOfStudy = await getIntendedYearsOfStudy();
          const jobFunctions = await getJobFunctions();
          setInstitutions(institutions);
          setSeniorityLevels(seniorityLevels);
          setIntendedYearsOfStudy(orderYearsOfStudy(intendedYearsOfStudy));
          setJobFunctions(jobFunctions);

          setTitles(titles);
          setIndustries(industries);
        } catch (e) {
          setError(true);
        }
      };

      submitCVFormData();
    }
  }, [loggedInUser]);

  const primaryFormFields: any = [
    {
      propertyName: "emailAddress",
      validation: {
        isRequired: true,
        email: true,
        maxLength: 100,
      },
      labelText: emailLabelText,
      dataText: userPersonalDetails?.result?.emailAddress,
    },
    {
      propertyName: "title",
      validation: {
        isRequired: true,
      },
      options: titles,
      formType: "select",
      labelText: titleLabelText,
      selectValue: findMatchingSelectValue(titles, titlesSelectValue),
      setValue: setTitlesSelectValue,
      dataText: findMatchingSelectValue(
        titles,
        userPersonalDetails?.result?.title
      ),
    },
    {
      propertyName: "firstName",
      validation: {
        isRequired: true,
        maxLength: 30,
        firstName: true,
      },
      labelText: firstNameLabelText,
      dataText: userPersonalDetails?.result?.firstName,
    },
    {
      propertyName: "lastName",
      validation: {
        isRequired: true,
        maxLength: 30,
        lastName: true,
      },
      labelText: familyNameLabelText,
      dataText: userPersonalDetails?.result?.lastName,
    },
    ...(![ProgrammeTypeGroup.MBA1Y].includes(programmeTypeGroup)
      ? [
          {
            validation: {
              telephone: true,
            },
            optionalText: false,
            hintText: telephoneNumberHintText,
            propertyName: "telephoneNumber",
            labelText: telephoneNumberLabelText,
            dataText: userPersonalDetails?.result?.telephoneNumber,
          },
        ]
      : []),
  ];

  const secondaryFormFields: any = [
    {
      validation: {
        maxLength: 50,
        isRequired: true,
      },
      propertyName: "nationality",
      labelText: nationalityLabelText,
      dataText: userPersonalDetails?.result?.nationality,
    },
    {
      validation: {
        maxLength: 50,
        isRequired: true,
      },
      propertyName: "homeCountry",
      labelText: homeCountryLabelText,
      dataText: userPersonalDetails?.result?.address?.homeCountry,
    },
    {
      validation: {
        maxLength: 50,
      },
      propertyName: "city",
      labelText: homeCityLabelText,
      dataText: userPersonalDetails?.result?.address?.city,
    },
    ...([ProgrammeTypeGroup.MBA1Y].includes(programmeTypeGroup)
      ? [
          {
            propertyName: "institution",
            validation: {
              required: "Institution is required",
              validate: (value) =>
                !!hasInstitutionMatch(value, institutions) ||
                "Please select a valid institution or None of these",
            },
            labelText: institutionLabelText,
            options: institutions,
            selectValue: findMatchingSelectValue(
              institutions,
              institutionsSelectValue
            ),
            dataText: "",
          },
          {
            propertyName: "masterProgrammeDegreeTitle",
            validation: {
              isRequired: true,
            },
            labelText: mastersProgrammeDegreeTitleLabelText,
            dataText: "",
          },
        ]
      : []),
    ...([ProgrammeTypeGroup.EMBAG].includes(programmeTypeGroup)
      ? [
          {
            validation: {
              maxLength: 50,
              isRequired: true,
            },
            dataText: userPersonalDetails?.result?.employment?.jobTitle,

            propertyName: "jobTitle",
            labelText: workJobTitleLabelText,
          },
        ]
      : []),
    ...([ProgrammeTypeGroup.MBA, ProgrammeTypeGroup.MIF].includes(
      programmeTypeGroup
    )
      ? [
          {
            validation: {
              maxLength: 50,
              isRequired: true,
            },
            formType: "select",
            options: industries,
            propertyName: "industry",
            labelText: industryLabelText,
            selectValue: findMatchingSelectValue(
              industries,
              industriesSelectValue
            ),
            setValue: setIndustriesSelectValue,
            dataText: findMatchingSelectValue(
              industries,
              userPersonalDetails?.result?.broadIndustry
            ),
          },
          {
            formType: "select",
            options: jobFunctions,
            propertyName: "jobFunction",
            labelText: functionLabelText,
            selectValue: findMatchingSelectValue(
              industries,
              jobFunctionSelectValue
            ),
            setValue: setJobFunctionSelectValue,
            dataText: findMatchingSelectValue(
              jobFunctions,
              userPersonalDetails?.result?.function
            ),
          },
        ]
      : []),
    ...([
      ProgrammeTypeGroup.MBA,
      ProgrammeTypeGroup.MIF,
      ProgrammeTypeGroup.EMBA,
      ProgrammeTypeGroup.SLOAN,
    ].includes(programmeTypeGroup)
      ? [
          {
            validation: {
              maxLength: 50,
              isRequired: true,
            },
            formType: "select",
            // data not provided in the mock API
            options: seniorityLevels,
            propertyName: "jobLevel",
            labelText: jobLevelLabelText,
            selectValue: findMatchingSelectValue(
              seniorityLevels,
              seniorityLevelsSelectValue
            ),
            setValue: setSeniorityLevelsSelectValue,
            dataText: findMatchingSelectValue(
              seniorityLevels,
              userPersonalDetails?.result?.jobLevel
            ),
          },
        ]
      : []),
    ...([
      ProgrammeTypeGroup.MBA,
      ProgrammeTypeGroup.MIF,
      ProgrammeTypeGroup.EMBAG,
      ProgrammeTypeGroup.MBA1Y,
    ].includes(programmeTypeGroup)
      ? [
          {
            validation: {
              maxLength: 50,
              isRequired: true,
            },
            dataText: userPersonalDetails?.result?.employment?.company,

            propertyName: "company",
            labelText: workCompanyNameLabelText,
          },
        ]
      : []),
    ...([
      ProgrammeTypeGroup.MBA,
      ProgrammeTypeGroup.MAM,
      ProgrammeTypeGroup.MIF,
      ProgrammeTypeGroup.MIM,
      ProgrammeTypeGroup.MFA,
      ProgrammeTypeGroup.EMBAG,
      ProgrammeTypeGroup.MBA1Y,
    ].includes(programmeTypeGroup)
      ? [
          {
            validation: {
              range: { min: 0, max: 100 },
              integerValidator: true,
              isRequired: true,
            },
            dataText: userPersonalDetails?.result?.yearsOfExperience,

            propertyName: "yearsOfExperience",
            labelText: yearsRelevantExperienceLabelText,
          },
        ]
      : []),
    ...([ProgrammeTypeGroup.EMBA, ProgrammeTypeGroup.SLOAN].includes(
      programmeTypeGroup
    )
      ? [
          {
            validation: {
              integerValidator: true,
              isRequired: true,
            },
            dataText: userPersonalDetails?.result?.yearsOfManagerialExperience,
            propertyName: "yearsOfManagerialExperience",
            labelText: yearsManagerialExperienceLabelText,
          },
        ]
      : []),
    ...([ProgrammeTypeGroup.EMBA, ProgrammeTypeGroup.SLOAN].includes(
      programmeTypeGroup
    )
      ? [
          {
            validation: {
              integerValidator: true,
            },
            optionalText: true,
            propertyName: "EAScore",
            labelText: executiveAssessmentScoreTitle,
            dataText: userPersonalDetails?.result?.scores?.eaScore,
          },
        ]
      : []),
    ...([
      ProgrammeTypeGroup.MAM,
      ProgrammeTypeGroup.MIM,
      ProgrammeTypeGroup.MFA,
    ].includes(programmeTypeGroup)
      ? [
          {
            dataText:
              userPersonalDetails?.result?.education?.academicInstitution,
            validation: {
              isRequired: true,
              maxLength: 50,
            },
            propertyName: "academicInstitution",
            labelText: educationalInstitutesLabelText,
          },
        ]
      : []),
    ...([ProgrammeTypeGroup.MFA].includes(programmeTypeGroup)
      ? [
          {
            validation: {
              isRequired: true,
            },
            propertyName: "studyDiscipline",
            labelText: studyDisciplineLabelText,
            dataText: userPersonalDetails?.result?.education?.studyDiscipline,
          },
        ]
      : []),
    ...([
      ProgrammeTypeGroup.MAM,
      ProgrammeTypeGroup.MIM,
      ProgrammeTypeGroup.MFA,
    ].includes(programmeTypeGroup)
      ? [
          {
            validation: {
              isRequired: true,
            },
            propertyName: "yearOfGraduation",
            labelText: graduationYearLabelText,
            dataText: userPersonalDetails?.result?.education?.yearOfGraduation,
          },
          {
            validation: {
              isRequired: true,
            },
            propertyName: "expectedGrade",
            labelText: expectedGradeLabelText,
            dataText: userPersonalDetails?.result?.education?.expectedGrade,
          },
        ]
      : []),
    ...([
      ProgrammeTypeGroup.MBA,
      ProgrammeTypeGroup.MIF,
      ProgrammeTypeGroup.EMBA,
      ProgrammeTypeGroup.SLOAN,
      ProgrammeTypeGroup.EMBAG,
    ].includes(programmeTypeGroup)
      ? [
          {
            validation: {
              integerValidator: true,
            },
            dataText: userPersonalDetails?.result?.scores?.gmatScore,
            labelText: gmatScoreLabelText,
            propertyName: "GMATScore",
          },
        ]
      : []),
    ...([
      ProgrammeTypeGroup.MBA,
      ProgrammeTypeGroup.MIF,
      ProgrammeTypeGroup.EMBA,
      ProgrammeTypeGroup.SLOAN,
      ProgrammeTypeGroup.EMBAG,
    ].includes(programmeTypeGroup)
      ? [
          {
            formType: "select",
            propertyName: "intendedYearOfStudy",
            labelText: intendedYearOfStudyLabelText,
            validation: {
              integerValidator: true,
              isRequired: true,
            },
            hintText: "",
            optionalText: false,
            options: intendedYearsOfStudy,
            selectValue: findMatchingSelectValue(
              intendedYearsOfStudy,
              intendedYearsOfStudySelectValue
            ),
            setValue: setIntendedYearsOfStudySelectValue,
            dataText: findMatchingSelectValue(
              intendedYearsOfStudy,
              userPersonalDetails?.result?.intendedYearOfStudy
            ),
          },
        ]
      : []),
    ...([
      ProgrammeTypeGroup.EMBA,
      ProgrammeTypeGroup.MIM,
      ProgrammeTypeGroup.MIF,
    ].includes(programmeTypeGroup)
      ? [
          {
            propertyName: "programmeSection",
            labelText: "",
            hintText: "",
            optionalText: false,
            options: null,
            validation: {
              isRequired: true,
            },
          },
        ]
      : []),
    {
      propertyName: "password",
      validation: {
        isRequired: true,
        maxLength: 30,
        password: true,
      },
      type: "password",
      hintText:
        passwordHintText ||
        "Minimum 12 characters. Must contain at least one alphabetic character and one number",
      labelText: passwordLabelText || "Password",
    },
    {
      propertyName: "confirmPassword",
      validation: {
        isRequired: true,
        maxLength: 30,
        confirmPassword: true,
      },
      disabled: !watch("password"),
      type: "password",
      labelText: confirmPasswordLabelText || "Confirm password",
    },
  ];

  useEffect(() => {
    if (userPersonalDetails) {
      const inputProp = [...primaryFormFields, ...secondaryFormFields].reduce(
        (o, key) => ({ ...o, [key?.propertyName]: key.dataText }),
        {}
      );
      reset(inputProp);
    }
  }, [userPersonalDetails]);

  useEffect(() => {
    setGenuinePath(router?.query?.referPage || router?.asPath);
  }, [router?.query, router?.query?.programmeTypeGroup]);

  const sendGAEvent = (event) => {
    setGADataLayer({
      event,
      formName: "submitCV",
      programmeCode: "",
      businessUnit: getBusinessUnit(),
    });
  };

  useEffect(() => {
    const subscription = watch((e, a) => {
      if (a.type === "change" && firstInteract < 1) {
        setGADataLayer({
          event: "formStart",
          formName: "submitCV",
        });
        firstInteract++;
        return () => subscription.unsubscribe();
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const onSubmit = async (data) => {
    setLoading(true);
    !uploadedFile && data.CV && delete data.CV;

    const institutionId =
      institutions?.find((item) => item.value === data?.institution)?.id ||
      null;

    const collectedData = {
      profile: {
        isStayInformed: canContactByEmail || data?.isStayInformed === "true",
        emailAddress: data?.emailAddress || null,
        title: +selectedIdValues.title || null,
        firstName: data?.firstName || null,
        lastName: data?.lastName || null,
        password: data?.password || null,
      },
      programmeType: {
        isProgrammeTypeRequired: !!router?.query?.programmeTypeCode,
        programmeTypeCode: router?.query?.programmeTypeCode || null,
        programmeTypeGroup: router?.query?.programmeTypeGroup || null,
      },
      yearsOfExperience: +data?.yearsOfExperience || null,
      academicInstitution:
        data?.institutionFreeText || data?.institution || data?.university,
      yearsOfManagerialExperience: +data?.yearsOfManagerialExperience || null,
      industry: +selectedIdValues.industry || null,
      function: +selectedIdValues.jobFunction || null,
      jobLevel: +selectedIdValues.jobLevel || null,
      EAScore: +data?.EAScore || null,
      studyDiscipline: data?.studyDiscipline || null,
      expectedGrade: data?.expectedGrade || null,
      GMATScore: +data?.GMATScore || null,
      yearOfStudy: +selectedIdValues?.intendedYearOfStudy || null,
      CVFile: uploadedFile || null,
      programmeTypesOfInterest: data?.selectedProgrammesList || null,
      nationality: selectedNationalCountry || null,
      homeCountry: selectedCountry || null,
      countryState: selectedCountryState || null,
      prerequisiteInstitution: institutionId || null,
      prerequisiteProgrammeTitle: data?.masterProgrammeDegreeTitle || null,
      city: data?.city || null,
      telephoneNumber: data?.telephoneNumber || null,
      company: data?.company || null,
      jobTitle: data?.jobTitle || null,
      yearOfGraduation: +data?.yearOfGraduation || null,
      linkedInUrl: data?.linkedInProfile || null,
    };
    let transformedData = objectToFormData(collectedData);

    try {
      if (loggedInUser) {
        setSubmittedUserEmail(data?.emailAddress);
        const response = await setSubmitCVLoggedIn(
          sessionStorage.getItem("crm_campaign"),
          transformedData,
          authToken
        );
        setLoading(false);
        if (response.status === 201) {
          setHasSubmitted(true);
        } else {
          const handledSubmitFormErrors = handleSubmitFormErrors(
            response,
            apiErrorMessages
          );
          window.scrollTo(0, 0);
          setHandledSubmitFormErrors(handledSubmitFormErrors);
        }
      }

      if (!loggedInUser) {
        const response = await setSubmitCVLoggedOut(
          sessionStorage.getItem("crm_campaign"),
          transformedData
        );
        setLoading(false);
        if (response.status === 201) {
          setHasSubmitted(true);
        } else {
          const handledSubmitFormErrors = handleSubmitFormErrors(
            response,
            apiErrorMessages
          );
          window.scrollTo(0, 0);
          setHandledSubmitFormErrors(handledSubmitFormErrors);
        }
      }
    } catch (e) {
      const handledSubmitFormErrors = handleSubmitFormErrors(
        null,
        apiErrorMessages
      );
      setHandledSubmitFormErrors(handledSubmitFormErrors);
      setLoading(false);
    }
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setUploadedFile(selectedFile);
    }
  };

  useEffect(() => {
    if (
      (!uploadedFile &&
        watchedFieldValue &&
        LINKEDIN_URL_PATTERN_REG_EXP.test(watchedFieldValue)) ||
      (uploadedFile && !watchedFieldValue)
    ) {
      clearErrors("linkedInProfile");
      clearErrors("CV");
    }
  }, [uploadedFile, watchedFieldValue]);

  if (hasSubmitted) {
    sendGAEvent("formComplete");
    if (router?.query?.programmeTypeGroup === "EMBAG") {
      router.push({
        pathname: "/embag/embag-submit-cv-success",
        query: {
          returnToPreviousPageUrl: siteSettings?.embagSiteUrl,
        },
      });
    } else {
      router.push({
        pathname: "/submit-cv-success",
        query: {
          returnToPreviousPageUrl: genuinePath,
          referPage: genuinePath,
        },
      });
    }
  }

  const FieldJSX = ({ labelText = "", dataText }) => (
    <div className={theme["field-row"]}>
      <label>{labelText}:</label>
      {dataText ? <p>{dataText}</p> : ""}
    </div>
  );

  const onRemove = () => {
    setUploadedFile(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
    clearErrors("CV");
  };

  const introLoggedInTextOpt =
    programmeTypeGroup === "EMBAG" ? embagIntroLoggedInText : introLoggedInText;
  const introTextOpt =
    programmeTypeGroup === "EMBAG" ? embagIntroText : introText;

  const { ref, ...rest } = register("CV", {
    onChange: handleFileChange,
    validate: {
      validate: (files) => {
        if (watchedFieldValue && files[0]) {
          return LINKEDIN_URL_OR_CV_VALIDATION_MESSAGE;
        }
        const checkFormat =
          files[0]?.name.endsWith(".doc") ||
          files[0]?.name.endsWith(".docx") ||
          files[0]?.name.endsWith(".pdf") ||
          files[0]?.name.endsWith(".rtf") ||
          "Invalid CV file type uploaded. (Must be pdf, doc, rtf or docx)";
        return files[0]
          ? checkFormat
          : formState.errors["LinkedIn"]
            ? "CV or LinkedIn profile is required"
            : true;
      },
    },
  });

  if (!router?.query?.programmeTypeCode || !router?.query?.programmeTypeGroup)
    return (
      <div className={clsx(theme["edit-details"], "wrapper")}>
        <div className={styles.spinner}>
          <ClipLoader
            color="#001e62"
            size={70}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      </div>
    );

  return (
    <div className={clsx(theme["edit-details"], "wrapper")}>
      <Grid row>
        <Grid column sm={12} md={6}>
          <div className={clsx(theme.component, "component", theme.form)}>
            <h1 className="h2">
              {loggedInUser ? formLoggedInTitle : formTitle}
            </h1>
            {error && (
              <FormErrorMessage
                failedRichText={failedMessage?.fields?.content}
                failedTinyMceRichText={failedMessage?.fields?.contentTinyMce}
              />
            )}
            {handledSubmitFormErrors &&
              handledSubmitFormErrors.map((error, index) => (
                <FormErrorMessage
                  key={index}
                  text={error}
                  userEmail={submittedUserEmail}
                />
              ))}
          </div>
          <section
            className={clsx(
              theme.component,
              theme["my-profile"],
              theme["form"],
              "form",
              theme.cf
            )}
          >
            <form className="form" onSubmit={handleSubmit(onSubmit)}>
              <div className={theme["fields-floated"]}>
                <>
                  <Grid row>
                    <Grid column sm={12}>
                      <p>
                        {loggedInUser ? introLoggedInTextOpt : introTextOpt}
                      </p>
                      <br />
                      <br />
                      {!loggedInUser && (
                        <p>
                          Already registered?{" "}
                          <a className="underline" href={LOGIN_URL}>
                            Log in here.
                          </a>
                        </p>
                      )}
                    </Grid>
                  </Grid>

                  <section className={theme["form-group-wrapper"]}>
                    <div className={theme["form-row"]}></div>
                    {primaryFormFields.map((field, index) =>
                      loggedInUser &&
                      permanentFields.includes(field.propertyName) ? (
                        <FieldJSX
                          key={`${field?.labelText}-${index}`}
                          labelText={field?.labelText}
                          dataText={field?.dataText}
                        />
                      ) : (
                        <FormField
                          key={`${field.labelText}${index}`}
                          formType={field?.formType}
                          watch={watch}
                          validation={field?.validation}
                          type="text"
                          register={register}
                          property={field.dataText}
                          selectedIdValues={selectedIdValues}
                          setSelectedIdValues={setSelectedIdValues}
                          hintText={field?.hintText}
                          placeholder={field.labelText}
                          options={field.options}
                          optionalText={field?.optionalText}
                          name={field?.propertyName}
                          errors={formState.errors}
                        />
                      )
                    )}
                    <div className="CVorLinkedIn form component">
                      <h4 className={theme["section-title"]}>Please submit</h4>
                      <FormField
                        watch={watch}
                        validation={{
                          isLinkedIn: true,
                          dependency: uploadedFile,
                        }}
                        type="text"
                        register={register}
                        placeholder={linkedInProfileUrlLabel}
                        hintText={"LinkedIn profile URL"}
                        optionalText={false}
                        errors={formState.errors}
                        {...register("linkedInProfile", {
                          validate: {
                            profileOrCV: (value) => {
                              if (uploadedFile && value) {
                                return LINKEDIN_URL_OR_CV_VALIDATION_MESSAGE;
                              }
                            },
                          },
                        })}
                      />
                      <h4 className="section-title">Or</h4>
                      <div className="btn-and-text-wrapper">
                        <div
                          className={clsx(
                            "button-wrapper",
                            "form-row",
                            "form",
                            "focused-force",
                            formState.errors["CV"] && "error"
                          )}
                        >
                          <div className="form-field focused-force">
                            <div
                              className={clsx(
                                "field",
                                "file-upload",
                                theme["file-upload"]
                              )}
                            >
                              <input
                                {...rest}
                                className="file-upload"
                                name="CV"
                                type="file"
                                ref={(e) => {
                                  ref(e);
                                  fileInputRef.current = e;
                                }}
                              />
                              <label className="file-upload" htmlFor="CV">
                                <span className="icon-arrow">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    focusable="false"
                                  >
                                    <title>arrow</title>
                                    <path d="M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8-8-8z"></path>
                                  </svg>
                                </span>
                                <span>Upload CV</span>
                              </label>
                              {uploadedFile && (
                                <p className={theme["uploaded-file-name"]}>
                                  {uploadedFile?.name}
                                  <span className="remove-file">
                                    <b onClick={onRemove}>X Remove</b>
                                  </span>
                                </p>
                              )}
                            </div>
                            {formState.errors["CV"] && (
                              <div className="form-field">
                                <div
                                  className="field message-wrapper inline-message field_error_msg"
                                  tabIndex={0}
                                >
                                  <p className="message active">
                                    {formState.errors["CV"]?.message}
                                  </p>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      {[
                        ProgrammeCode.MAM,
                        ProgrammeCode.MFA,
                        ProgrammeCode.MIM,
                      ].includes(programmeCode) && (
                        <div className="form-row">
                          * Please ensure your CV includes your academic details
                          (institution, subject, grade and graduation date),
                          professional experiences and personal interests.
                        </div>
                      )}
                    </div>
                    {secondaryFormFields.map((field, index) => {
                      if (field.propertyName === "homeCountry") {
                        return (
                          <CountryStateSection
                            key={`${field.labelText}${index}`}
                            isShowRelatedStateField={true}
                            isNationalityVariant={false}
                            name={"countryOfResidence"}
                            programme={programmeTypeGroup}
                            register={register}
                            control={control}
                            setValue={setValue}
                            errors={formState.errors}
                            countryInputValue={countryInputValue}
                            setCountryInputValue={setCountryInputValue}
                            countries={countries}
                            setCountries={setCountries}
                            selectedCountry={selectedCountry}
                            setSelectedCountry={setSelectedCountry}
                            showCountriesList={showCountriesList}
                            setShowCountriesList={setShowCountriesList}
                            countriesStateList={countriesStateList}
                            setCountriesStateList={setCountriesStateList}
                            selectedCountryState={selectedCountryState}
                            setSelectedCountryState={setSelectedCountryState}
                            countryOfResidenceLabelText={homeCountryLabelText}
                            countryStateLabelText={
                              countryStateLabelText || "County/State"
                            }
                          />
                        );
                      }

                      if (field.propertyName === "institution") {
                        return (
                          <InstitutionSection
                            key={`${field.labelText}${index}`}
                            placeholder={field.labelText}
                            name={field.propertyName}
                            register={register}
                            setValue={setValue}
                            clearErrors={clearErrors}
                            errors={formState.errors}
                            value={watch(field.propertyName)}
                            getOptions={() => {}}
                            options={field?.options}
                            validation={field.validation}
                            watch={watch}
                            selectDescription="You must hold a management masters degree from an institution accredited by EQUIS/AACSB or from a University of London institution"
                            inputDescription="If you don’t hold a degree from any of the above Institutions, please add the Institution below:"
                          />
                        );
                      }

                      if (field.propertyName === "nationality") {
                        return (
                          <CountryStateSection
                            key={`${field.labelText}${index}`}
                            isNationalityVariant={true}
                            name={"nationalityOf"}
                            programme={programmeTypeGroup}
                            register={register}
                            control={control}
                            setValue={setValue}
                            errors={formState.errors}
                            countryInputValue={nationalityInputValue}
                            setCountryInputValue={setNationalityInputValue}
                            countries={nationalityCountries}
                            setCountries={setNationalityCountries}
                            selectedCountry={selectedNationalCountry}
                            setSelectedCountry={setSelectedNationalCountry}
                            showCountriesList={showNationalCountriesList}
                            setShowCountriesList={setShowNationalCountriesList}
                            countryOfResidenceLabelText={nationalityLabelText}
                          />
                        );
                      }

                      if (field.propertyName === "programmeSection") {
                        return (
                          <ProgrammesListSection
                            key={`${field.labelText}${index}`}
                            theme={theme}
                            register={register}
                            programme={programmeTypeGroup}
                            programmesListData={programmesListData}
                            programmesHeadingText={
                              "Which programmes interest you?"
                            }
                            programmesHintText={
                              "You must select Which programmes interest you?"
                            }
                            programmesSelectedTypes={programmesSelectedTypes}
                            setProgrammesSelectedTypes={
                              setProgrammesSelectedTypes
                            }
                            formState={formState}
                            name="selectedProgrammesList"
                          />
                        );
                      }

                      if (
                        (field.propertyName === "password" ||
                          field.propertyName === "confirmPassword") &&
                        loggedInUser
                      ) {
                        return null;
                      }

                      return (
                        <FormField
                          formType={field?.formType}
                          watch={watch}
                          validation={field?.validation}
                          type={field?.type || "text"}
                          register={register}
                          property={field.dataText}
                          selectedIdValues={selectedIdValues}
                          setSelectedIdValues={setSelectedIdValues}
                          key={`${field.labelText}${index}`}
                          optionalText={field?.optionalText}
                          placeholder={field.labelText}
                          options={field.options}
                          name={field?.propertyName}
                          errors={formState.errors}
                        />
                      );
                    })}
                  </section>

                  {!canContactByEmail && (
                    <FormPrivacySection
                      register={register}
                      policyType={
                        programmeTypeGroup === "EMBAG" ? "embag" : "regular"
                      }
                      formState={formState}
                      chosenEmailSubscription={
                        loggedInUser && loggedInStayInformed
                      }
                    />
                  )}

                  <div className="btn-and-text-wrapper">
                    <SubmitButton
                      loading={loading}
                      id="submitButton"
                      text="Submit"
                    />
                  </div>
                </>
              </div>
            </form>
          </section>
        </Grid>
      </Grid>
    </div>
  );
};

export default themr("SubmitCv", styles)(SubmitCv);

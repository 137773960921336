import AccordionContainer from "@components/C11_VerticalAccordion/AccordionContainer";
import Cta from "@components/Cta/Cta";
import Grid from "@components/Grid/Grid";
import ImageWrapper, {
  convertToImageWrapperType,
} from "@components/Image/ImageWrapper";
import { CourseTypeSetting } from "@customTypes/CourseTypeSettings";
import { FacultyMember } from "@customTypes/FacultyMember";
import { ProgrammeDetails } from "@customTypes/programmeDetails";
import { themr } from "@friendsofreactjs/react-css-themr";
import { useDevice } from "@utilities/react/get-device/get-device";
import clsx from "clsx";
import Link from "@components/Link/Link";
import { useEffect, useRef, useState } from "react";
import styles from "./C282_Electives.module.scss";

export interface Course extends CourseTypeSetting {
  courseType: string;
  courseList: Array<{
    courseTitle: string;
    courseDescription: string;
    facultyMembers?: FacultyMember[] | undefined;
    programmeList: ProgrammeDetails[] | undefined;
  }>;
}

interface ElectiveItemProps {
  index: number;
  course: Course;
  theme?: any;
  isOpen: boolean;
  closeAll: Function;
  openStates: boolean[];
  handleOpenStateChange: Function;
  singleDisplay: boolean;
  displayFacultyList: boolean;
}

export const ElectiveItem = (props: ElectiveItemProps) => {
  const {
    index,
    course,
    theme,
    closeAll,
    openStates,
    handleOpenStateChange,
    singleDisplay,
    displayFacultyList,
  } = props;
  const [lock, setLock] = useState(false);
  const [height, setHeight] = useState("auto");
  const outterContentRef: any = useRef();
  const innerContentRef: any = useRef();
  const device = useDevice();
  const isOpen = openStates[index];

  const getLinks = (programmeList: ProgrammeDetails[]) =>
    programmeList.map((programme) => ({
      linkText: programme.programmeTitle,
      url: programme.slug,
    }));

  const performOpen = () => {
    outterContentRef.current.visibility = "visible";
    outterContentRef.current.style.height =
      innerContentRef.current.offsetHeight + "px";
    setTimeout(() => {
      outterContentRef.current.style.height = "auto";
    }, 300);
  };

  const performClose = () => {
    outterContentRef.current.style.height =
      innerContentRef.current.offsetHeight + "px";
    setTimeout(() => {
      outterContentRef.current.style.height = 0;
    }, 0);
    setTimeout(() => {
      outterContentRef.current.visibility = "hidden";
    }, 300);
  };

  const handleToggle = (event) => {
    event.preventDefault();
    if (!lock) {
      setLock(true);
      setTimeout(() => {
        setLock(false);
      }, 300);

      if (isOpen) {
        handleOpenStateChange(index, false);
      } else {
        if (singleDisplay) closeAll();
        handleOpenStateChange(index, true);
      }
    }
  };

  useEffect(() => {
    if (isOpen) {
      performOpen();
    } else {
      performClose();
    }
  }, [openStates]);

  const applyHeight = () => {
    if (["desktop", "desktop-large"].includes(device)) {
      const items = document.querySelectorAll(
        ".item"
      ) as NodeListOf<HTMLElement>;
      const heights = [...items].map((item) => item.offsetHeight);
      const maxHeight = Math.max(...heights);
      setHeight(maxHeight + "px");
    }
  };

  useEffect(applyHeight, [device, course]);
  return (
    <li key={course.courseTypeTitle}>
      <div className={clsx(theme.item, "item", isOpen && theme["is--active"])}>
        <div className={theme["elective-details"]}>
          <h3 className={theme.title}>{course.courseTypeTitle}</h3>
          <p>{course.courseTypeDescription}</p>
          <a
            href="#"
            className={theme["toggle-action"]}
            aria-expanded={isOpen}
            onClick={handleToggle}
          >
            <span className={theme["show-more"]}>{course.accordionText}</span>
            <span className={theme["show-less"]}>Show less</span>
          </a>
        </div>
        <div
          ref={outterContentRef}
          aria-hidden={!isOpen}
          className={clsx(theme["elective-accordion-drop"])}
        >
          <div ref={innerContentRef} className={theme["elective-content"]}>
            <AccordionContainer
              noHeading
              accordionFields={{
                singleDisplay: false,
                defaultOpen: false,
                accordionItems: course.courseList.map((courseDetails) => ({
                  fields: {
                    title: courseDetails.courseTitle,
                    content: (
                      <Grid row>
                        <Grid column sm={12} md={8}>
                          {courseDetails?.courseDescription && (
                            <div
                              dangerouslySetInnerHTML={{
                                __html: courseDetails.courseDescription,
                              }}
                            ></div>
                          )}
                          {!!courseDetails?.programmeList &&
                            !!courseDetails?.programmeList.length && (
                              <>
                                <h5 className={theme["sub-title"]}>
                                  {course.courseProgrammeHeaderText}
                                </h5>
                                <ul className={theme["styled-list"]}>
                                  {getLinks(courseDetails?.programmeList).map(
                                    (link, i) => (
                                      <li key={link.url + link.linkText + i}>
                                        <Cta
                                          aria-expanded="false"
                                          type="tertiary"
                                          url={link.url}
                                          linkText={link.linkText || ""}
                                          icon="icon-arrow"
                                          customClass={theme["cu-text"]}
                                        />
                                      </li>
                                    )
                                  )}
                                </ul>
                              </>
                            )}
                        </Grid>
                        {displayFacultyList &&
                          courseDetails.facultyMembers &&
                          courseDetails.facultyMembers.length > 0 && (
                            <Grid column sm={12} md={4}>
                              <div className={theme["elective-profile"]}>
                                <h5 className={theme["elective-profile-title"]}>
                                  {course.courseFacultyHeaderText}
                                </h5>
                                <ul className={theme["elective-profile-list"]}>
                                  {courseDetails.facultyMembers.map(
                                    (facultyMember, index) => (
                                      <li
                                        key={`${facultyMember.profileFirstname}${index}`}
                                      >
                                        <div className={theme["profile-image"]}>
                                          <Link href={facultyMember.slug || ""}>
                                            <a>
                                              {facultyMember?.profilePhoto ? (
                                                <ImageWrapper
                                                  image={convertToImageWrapperType(
                                                    facultyMember?.profilePhoto
                                                  )}
                                                  width={60}
                                                  height={60}
                                                />
                                              ) : null}
                                            </a>
                                          </Link>
                                        </div>
                                        <div className={theme["profile-name"]}>
                                          <Link href={facultyMember.slug || ""}>
                                            <a>
                                              {facultyMember.profileFirstname}{" "}
                                              {facultyMember.profileSurname}
                                            </a>
                                          </Link>
                                        </div>
                                      </li>
                                    )
                                  )}
                                </ul>
                              </div>
                            </Grid>
                          )}
                      </Grid>
                    ),
                  },
                })),
              }}
            />
          </div>
        </div>
        <a
          href="#"
          aria-expanded={isOpen}
          onClick={handleToggle}
          className={clsx(
            theme["toggle-action"],
            theme["toggle-action--mobile"]
          )}
        >
          <span className={theme["show-less"]}>Show less</span>
        </a>
      </div>
    </li>
  );
};

export default themr("ElectiveItem", styles)(ElectiveItem);

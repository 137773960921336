import C15_SocialSharing from "@components/C15_SocialSharing/C15_SocialSharing";
import C200_1_BrandIconTitle from "@components/C200_1_BrandIconTitle/C200_1_BrandIconTitle";
import C30_SaveToMyProfile from "@components/C30_SaveToMyProfile/C30_SaveToMyProfile";
import Grid from "@components/Grid/Grid";
import { Author } from "@customTypes/Author";
import { ImageWrapperType } from "@customTypes/imageWrapper";
import { SearchLandingType } from "@customTypes/SearchLandingType";
import { themr } from "@friendsofreactjs/react-css-themr";
import { useSettings } from "@utilities/context/settings";
import RenderComponents from "@utilities/renderComponents";
import clsx from "clsx";
import { format, parseISO } from "date-fns";
import Head from "next/head";
import Link from "@components/Link/Link";
import { useRouter } from "next/router";
import { Fragment, useEffect, useState } from "react";
import { BSRContentPageProvider } from "../../../context/bsrContentPage";
import Image from "../../Image/Image";
import styles from "../article.module.scss";

export interface BSRContentPageProps {
  articleTitle: string;
  articleImage?: ImageWrapperType;
  articleThumbnail?: ImageWrapperType;
  articlePublishedDate: string;
  articleDescription?: string;
  articleTimeToRead?: string;
  shortDescription?: string;
  showArticleImage?: boolean;
  bodyContent: any;
  bodyContentBottom: any;
  authorsHeading?: string;
  authorsList?: Author[];
  vimeoVideoId?: string;
  youTubeVideoId?: string;
  soundcloudPodcastId?: string;
  basePageTemplate: { fields: any };
  tags: any;
}

export const BSRContentPage = (props: {
  content: BSRContentPageProps;
  theme?: any;
}) => {
  const { content, theme } = props;
  const {
    articleDescription,
    articleTimeToRead,
    articleImage,
    articlePublishedDate,
    bodyContent,
    bodyContentBottom,
    articleThumbnail,
    articleTitle,
    shortDescription,
    showArticleImage,
    authorsHeading,
    authorsList,
    vimeoVideoId,
    youTubeVideoId,
    soundcloudPodcastId,
    tags,
  } = content;

  const [url, setUrl] = useState("");
  const { route } = useRouter();

  const { siteSettings } = useSettings();

  const thinkTopicsPage =
    siteSettings?.searchResultsPagesCollection?.items.filter(
      ({ type }) => type === SearchLandingType.ThinkTopics
    )[0];

  const thinkAuthorsPage =
    siteSettings?.searchResultsPagesCollection?.items?.filter(
      ({ type }) => type === SearchLandingType.ThinkAuthors
    )[0];

  useEffect(() => {
    const url = document.URL;
    setUrl(url);
  }, [route]);

  const formattedDate = format(parseISO(articlePublishedDate), "yyyy-MM-dd");

  const schemaData = {
    "@context": "http://schema.org",
    "@type": "NewsArticle",
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id": `${url}`,
    },
    headline: `${articleTitle}`,
    image: [`https:${articleImage?.fields?.image?.fields?.file?.url}`],
    datePublished: `${formattedDate}`,
    dateModified: `${formattedDate}`,
    author: {
      "@type": "Person",
      name: "London Business School",
    },
    publisher: {
      "@type": "Organization",
      name: "London Business School",
      logo: {
        "@type": "ImageObject",
        url: "https://images.ctfassets.net/hxo16fanegqh/4KD0tsxILYEZKUprJxltZT/127b23210dfc15b641376e29117796a5/lbs-logo.png",
      },
    },
    description: `${articleDescription}`,
  };

  let articleMediaType = "Article";

  if (vimeoVideoId || youTubeVideoId) {
    articleMediaType = "Video";
  } else if (soundcloudPodcastId) {
    articleMediaType = "Podcast";
  }

  return (
    <BSRContentPageProvider details={{ authorsHeading, authorsList }}>
      <Head>
        <script type="application/ld+json">{JSON.stringify(schemaData)}</script>

        <meta
          name="articledateyear"
          content={format(parseISO(articlePublishedDate), "yyyy")}
        />
        <meta
          name="articledate"
          content={format(parseISO(articlePublishedDate), "yyyy-MM-dd")}
        />
        <meta
          name="articledatetext"
          content={format(parseISO(articlePublishedDate), "dd MMMM yyyy")}
        />
        <meta name="articlemediatype" content={articleMediaType} />
        <meta name="articletitle" content={articleTitle} />
        <meta name="ShortDescription" content={shortDescription} />
        {!!articleThumbnail && (
          <>
            <meta
              name="articleimagealt"
              content={articleThumbnail?.fields?.altText}
            />
            <meta
              name="articleimageurl"
              content={`${articleThumbnail?.fields?.image?.fields.file?.url}`}
            />
            <meta
              key="twitter:image"
              name="twitter:image"
              content={`${articleThumbnail.fields?.image?.fields.file?.url}?w=1200&h=1200`}
            />
            <meta
              key="og:image"
              property="og:image"
              content={`${articleThumbnail?.fields?.image?.fields.file?.url}?w=1200&h=600`}
            />
          </>
        )}
        {authorsList && (
          <meta
            name="articleauthorsnames"
            content={authorsList
              .map((author, index) => {
                return author.fields.articleAuthorName;
              })
              .join(";")}
          />
        )}
      </Head>
      <C200_1_BrandIconTitle isH2={true} />
      <div className="wrapper">
        <Grid row>
          <Grid column sm={12}>
            <section
              className={clsx("component", theme["article-header"], "cf")}
            >
              <div className="wrapper">
                <Grid row>
                  <Grid column sm={12}>
                    <h1>{articleTitle}</h1>
                    <p>{articleDescription}</p>
                  </Grid>
                </Grid>
                <Grid row>
                  <Grid column sm={12}>
                    <div className={theme["article-details-wrapper"]}>
                      <Grid row>
                        <Grid column sm={12} md={8}>
                          <div className={theme["article-details"]}>
                            {authorsList && (
                              <div className={theme.author}>
                                {authorsList.map((author, index) => {
                                  const i = index + 1;
                                  const facultyPage =
                                    author.fields?.articleAuthorFacultyProfile
                                      ?.fields.slug;

                                  const contributorPage =
                                    author.fields
                                      ?.articleAuthorContributorProfile?.fields
                                      .slug;
                                  console.log(facultyPage || contributorPage);
                                  const authorshipLink =
                                    facultyPage || contributorPage
                                      ? `/${facultyPage || contributorPage}`
                                      : `/${thinkAuthorsPage?.url?.slug}?${
                                          thinkAuthorsPage?.queryParams
                                        }=${author.fields.articleAuthorName.replaceAll(
                                          " ",
                                          ""
                                        )}`;
                                  return (
                                    <Fragment
                                      key={author.fields.articleAuthorName}
                                    >
                                      <Link
                                        key={`author-${author.fields.articleAuthorName}`}
                                        href={authorshipLink}
                                      >
                                        {author.fields.articleAuthorName}
                                      </Link>
                                      {i < authorsList.length - 1 && ", "}
                                      {i === authorsList.length - 1 && " and "}
                                    </Fragment>
                                  );
                                })}
                              </div>
                            )}

                            <div className={theme["date-read-time"]}>
                              <span className={theme.date}>
                                {format(
                                  parseISO(articlePublishedDate),
                                  "dd MMMM yyyy"
                                )}
                              </span>
                              {articleTimeToRead && (
                                <span className={theme["read-time"]}>
                                  {articleTimeToRead}
                                </span>
                              )}
                            </div>
                          </div>

                          <div
                            className={clsx(
                              "component",
                              "tag",
                              theme.tag,
                              "cf"
                            )}
                          >
                            {tags &&
                              tags.map((item) => {
                                const tagName = item.name.split(":")[1];
                                return (
                                  <Link
                                    key={`newsTopic-${tagName}`}
                                    href={`/${thinkTopicsPage?.url?.slug}?${thinkTopicsPage?.queryParams}=${tagName}`}
                                  >
                                    {tagName}
                                  </Link>
                                );
                              })}
                          </div>
                        </Grid>
                        <Grid column sm={12} md={4}>
                          <div className={theme.share}>
                            <C15_SocialSharing
                              socialLinks={
                                siteSettings?.globalSocialShareCollection
                              }
                              isSocialIconDark
                              small
                              mobileAlignment
                            />
                            <C30_SaveToMyProfile />
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </section>
            {articleImage && showArticleImage && (
              <section
                className={clsx(
                  "component",
                  theme["article-images"],
                  "full-width",
                  "cf"
                )}
              >
                <Image
                  src={articleImage.fields.image.fields.file.url}
                  alt={articleImage?.fields?.altText || ""}
                  width={570}
                  height={173}
                  desktopWidth={1140}
                  desktopHeight={346}
                />
              </section>
            )}
          </Grid>
        </Grid>
      </div>
      {RenderComponents(bodyContent)}
      {RenderComponents(bodyContentBottom)}
    </BSRContentPageProvider>
  );
};

export default themr("BSRContentPage", styles)(BSRContentPage);
